import Emittery from 'emittery'
import { defineNuxtPlugin, onGlobalSetup, provide } from '@nuxtjs/composition-api'
import type { CartProduct, Product, ProductExtended } from '~/type/product/Product'
import type Sale from '~/type/Sale'
import type Banner from '~/type/Banner'
import type SalePromo from '~/type/SalePromo'
import type User from '~/type/User'

export interface Events {
  // Событие добавления пользователем товара в корзине
  'cart-product-add': { product: Partial<ProductExtended> & { quantity: number; source?: string; saleName?: string }; cart?: { [key: number]: CartProduct } }

  // Событие удаления пользователем товара в корзине
  'cart-product-remove': { product: Partial<ProductExtended> & { quantity: number; saleName?: string }; cart?: { [key: number]: CartProduct } }

  // Событие создания заказа
  'after-checkout': {
    order: any
    userId?: number
  }

  // Событие очистки товаров из избранного
  'clear-favorite-list': never

  // Получить список товаров корзины
  'get-cart-list': {
    productListId: string[]
    productListItem: {
      [key: string]: {
        quantity: number
      }
    }
  }

  // Получить список товаров в избранном
  'get-favorite-list': { productListId: string[] }

  // Событие перехода в корзину
  'begin-checkout': { productList: CartProduct[] }

  // Событие клика по товару из списка товаров
  'product-select': { product: Product; index?: number; listName: string; saleName?: string }

  // Событие просмотра списка товаров
  'product-list-view': { list: Product[]; listName: string; category?: string }

  // Событие клика по баннеру акции
  'sale-select': { sale: Sale }

  // Событие клика по баннеру
  'banner-select': { banner: Banner }

  // Событие отправки формы
  'submit-form-data': { phone?: string; email?: string }

  // Событие открытия страницы
  'sale-view': {
    sale: SalePromo
    product: Product[]
  }

  // Событие отправки отмены заказа
  'order-reject-submitted': { id: number; arr: CartProduct[]; bonus?: number }

  // Событие добавления товара в сравнение
  'add-to-compare': { product: Product }

  // Событие добавления товара в избранное
  'add-to-favorite': { product: Product; listName?: string; index?: number }

  // Событие удаления товара из избранного
  'remove-from-favorite': { id: string }

  // Событие подтверждения пользователем поискового запроса
  'search-confirm': {
    searchQuery: string
    searchSource?: 'history' | 'popular' | 'hints' | 'confirm'
  }

  'quantity-search': {
    searchQuery: string
    quantity: number
  }

  // Событие после выбора результата из поисковой выдачи
  'select-search-result': {
    searchQuery: string
    selectResultType: 'product' | 'catalog'
    selectResultItem: string
    index?: number
  }

  // Событие показа опроса на странице поиска
  'show-search-survey': never

  // Событие при просмотре товара
  'product-view': { product: ProductExtended & { quantity: number }; statusCode: string }

  // Событие создания уведомления о том, чтобы пользователь оставил отзыв
  'notification-order-review-created': never

  // Событие клика по баннеру на главной
  'mainpage/on-sale-banner-click': never

  // Событие клика по категории на главной
  'mainpage/on-category-click': never

  // Событие создания отзыва
  'review-created': never

  // Событие расскрытия группы фильтров в модалке фильтров мобильной версии
  'click-filter-arrow-property': never

  // Событие клика по инфоблоку авторизации
  'mainpage/on-infoblock-auth-click': never

  // Событие быстрого заказа
  'quick-order-submitted': { phone: string }

  // Событие открытия формы для фидбека по фиче "Поделиться бонусами"
  'share-bonus-modal-close': never

  // Событие подтверждения выбора города
  'set-user-city': never

  // Событие выбора экспресс доставки
  'express-delivery': never

  // События с предложением оставить отзыв о заказе
  'show-order-review': never

  // События клика для оценки отзыва
  'click-order-review': never

  // Событие результата пустого поиска
  'on-empty-search': never

  // Событие пройденого этапа в окне оформления
  'click-next-checkout-step': { step: number }

  // Событие клика по ссылке из популярных категорий
  'mainpage/on-popular-category-click': { event: string }

  // Событие открытия страницы сайта в новой вкладке
  'page-open': {
    level_name: string
  }

  // Событие при вступлении/выходе из программы лояльности
  'change-loyalty-status': {
    name: 'exit' | 'enter'
  }

  // Событие открытия формы кредита
  'loan-form-open': never

  // Событие просмотра категории
  'view-category': { id: string; name: string }

  // Событие просмотра страницы категории
  'view-catalog-page': { id: string }

  // Событие при изменении личных данных пользователем
  'change-user-data': User

  // Событие добавление в корзину товара из аксессуаров
  'add-to-cart-accessory': { product: ProductExtended; type: 'card' | 'tab' }

  // Событие авторизации, содержит тип авторизации (пароль, смс) и телефон или почту
  'auth-passed': Partial<{
    phone: string
    activeTypeBlock: string
    username: string
  }>

  'get-auth-type': { type: 'sms' | 'password' }

  // Событие промокода, внутри его статус и цена скидки (чтобы проверить, применилась ли она)
  'apply-promocode': { status: 'success' | 'error'; discount?: number }

  // Событие при отправке заявки на быстрое оформление
  'fast-ordering': {
    phone: string
    product: Partial<Product>
  }

  // Событие при выборе времени доставки
  'click-delivery-time': never

  // Событие при выборе даты доставки
  'click-delivery-date': { date: string }

  // Событие при выборе типа доставка
  'delivery-select': { type: string }

  // Событие шагов рассрочки
  'installment-step': { step: number }

  // Событие иницилизации формы поскредита
  'loan-poscredit-init': never

  // Событие при клике на "Купить в рассрочку"
  'loan-by-installment': never

  // Событие при экпресс оформлении
  'loan-available-express': never

  // Событие успешного оформления экспресс рассрочки
  'loan-success-express': never

  // Событие, когда запрос упал с таймаутом
  'loan-timeout-error': never

  // Событие, когда запрос упал с таймаутом
  'loan-request-error': { error: { name: string; phone: string; fullname: string; code: string; native: { message: string; fields?: string[] } } }

  // просмотр какого-то АБ теста
  'ab-test-viewed': {
    experimentId: string
    variationId: string
  }

  // клик по одному из баннеров авторизации на главной странице
  'mainpage/on-auth-banner-click': {
    experimentId: string
    variationId: number
  }

  // событие модалки "Как оплатить" ЛК рассрочки
  'loan-to-pay': never

  // событие модалки с детельной инфой о рассрочки
  'loan-open-details': {
    paymentOverdue: boolean
  }

  'loan-open-user': {
    isAuth: boolean
  }
}

export type EmitteryType = InstanceType<typeof Emittery<Events>>

export default defineNuxtPlugin((_, inject) => {
  const emitter = new Emittery<Events>()
  inject('emitter', emitter)

  onGlobalSetup(() => {
    provide('emitter', emitter)
  })
})

declare module 'vue/types/vue' {
  interface Vue {
    $emitter: EmitteryType
  }
}

declare module '@nuxt/types' {
  interface Context {
    $emitter: EmitteryType
  }

  interface NuxtAppOptions {
    $emitter: EmitteryType
  }
}
